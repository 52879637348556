body {
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #212112;
  background-color: #f6f6f6;
  width: 100%;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

/*============start Login page============*/
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.login-form {
  background-color: rgba(255, 255, 255, 0.95);
  padding: 15px 30px;
  border: none;
  color: #333;
  box-shadow: 0 0 5px 0 rgb(0 0 0 / 20%);
  margin-top: 40px;
  width: 100%;
  float: left;
  background: #fff;
  box-shadow: 0 1px 5px #40a2ed;
  padding: 20px 40px;
  margin-top: 10%;
  margin-bottom: 12%;
  border-top: 5px solid #40a2ed;
  border-radius: 10px;
}

.login_contnainer {
  padding: 0% 8%;
  color: #000;
  width: 100%;
  float: left;
  height: 100%;
  background-image: url("../img/Rancho-Cucamonga-CA1.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  position: absolute;
}

.content-top-agile h2 {
  font-weight: 400;
  font-size: 1.7rem;
  text-align: center;
}

.content-top-agile p {
  color: rgb(62 59 59 / 50%) !important;
  text-align: center;
}

.frm-Login span.ant-input-affix-wrapper {
  height: 47px;
  border: 1px solid #d5d3d3;
  border-radius: 3px;
  background: #f7f6f6;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.frm-Login
  .ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper > input.ant-input {
  background-color: #f7f6f6;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.frm-Login .ant-input-affix-wrapper > input.ant-input::placeholder {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.login-form-button button.ant-btn.ant-btn-primary {
  background: #40a2ed;
  font-size: 15px;
  font-weight: 600;
  color: #fff;
  padding: 9px 24px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
  height: auto;
  border-radius: 5px;
  text-transform: uppercase;
}

.login-form-button button.ant-btn.ant-btn-primary:hover {
  background: #000;
}

.basic_checkbox_0 label.ant-checkbox-wrapper {
  font-size: 14px;
  color: #646464;
  font-weight: 600;
}

a.login-form-forgot {
  font-weight: 500;
  text-align: end;
  width: 100%;
  float: left;
}

.content-top-agile {
  width: 100%;
  float: left;
  text-align: center;
}

.content-top-agile img {
  width: 150px;
  height: 150px;
  border-radius: 100%;
  margin-bottom: 15px;
}

.content-top-agile h3 {
  font-size: 26px;
}

.sign-with p {
  margin-top: 3px;
  margin-bottom: 0px;
  color: #000;
  font-size: 17px;
  text-align: center;
}

.account-li {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  float: left;
  margin-top: 4%;
}

p.account-li a {
  color: #40a2ed;
  font-weight: 600;
  padding: 0px 6px;
}

p.account-li {
  margin-top: 7px;
}

/*============end Login page============*/

/*============start navbar============*/

.name-fl-l {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 65%;
  float: left;
  flex-direction: row;
  flex-wrap: wrap;
}

.name-fl-l p {
  margin: 0;
  margin-right: 4px;
  line-height: 19px;
}

.logo-nv {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  float: left;
  margin-top: 20px;
  margin-bottom: 20px;
}

.logo-nv h2 {
  margin: 0;
  font-size: 30px;
  color: #1890fe;
  text-transform: uppercase;
}

.togglebtn0 svg {
  font-size: 23px;
  margin-right: 20px;
}

.logo-nav h2 {
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
}

.site-layout .site-layout-background {
  background: #fff;
}

.sidebar_nv {
  background: #fff;
  /* height: 100%; */
}

.sidebar_nv ul.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
  padding-bottom: 59px;
  height: 100%;
  /* position: fixed;
    width: 200px;
    top: 59px; */
  padding-top: 37%;
}

.sidebar_nv
  ul.ant-menu.ant-menu-root.ant-menu-vertical.ant-menu-light.ant-menu-inline-collapsed {
  padding-top: 82%;
}
.sidebar_nv span.ant-menu-title-content {
  font-weight: 500;
}
.image_nv img {
  width: 40px;
  height: 40px;
  border-radius: 100px;
}
.sidebar_nv aside.ant-layout-sider::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.sidebar_nv aside.ant-layout-sider::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.sidebar_nv aside.ant-layout-sider::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.sidebar_nv aside.ant-layout-sider::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.nav_dropuser a.ant-dropdown-trigger.ant-dropdown-link {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 50px;
  color: #475f7b;
  font-size: 13px;
  width: 100%;
  float: left;
}

.nav_dropuser {
  width: 36%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image_nv {
  margin-right: 6px;
  width: 35%;
  float: left;
  text-align: end;
}

header.ant-layout-header.navbar_top {
  padding: 0px 23px !important;
  color: #475f7b;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  width: 100%;
  float: left;
  position: sticky;
  top: 0;
  z-index: 999;
}

.logouser {
  font-size: 24px;
  font-weight: 400;
  text-transform: uppercase;
}

.content_all {
  margin: 15px 16px;
  min-height: 600px;
  padding: 20px;
  height: 100%;
  /* margin-left: 200px; */
}

.sidebar_nv .ant-layout-sider {
  position: relative;
  min-width: 0;
  background: #ffffff;
  transition: all 0.2s;
  position: sticky;
  top: 0px;
}

.togglebtn0 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  float: left;
}

.dropdown-downmenu0 svg {
  margin-right: 8px;
}

.dropdown-downmenu0,
.dropdown-downmenu0 a,
.dropdown-downmenu0 span.ant-dropdown-menu-title-content {
  color: #475f7b;
  text-decoration: none;
  font-size: 13px;
}

.notifications-menu-nv {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  line-height: 50px;
  color: #475f7b;
  width: 46%;
  float: left;
}

.noti-userdrpdown {
  display: flex;
  width: 100%;
  float: left;
  justify-content: flex-end;
  align-items: center;
}

.notifications-menu-nv a.ant-dropdown-trigger.ant-dropdown-link {
  display: flex;
  justify-content: center;
  align-items: center;
}

.notifications-menu-nv i {
  font-size: 22px;
  color: #333;
}

.flexbox-nv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  float: left;
  padding: 11px 0px;
  line-height: 10px;
}

.flexbox-nv h4 {
  font-size: 16px;
  color: #475f7b;
  margin: 0;
}

.flexbox-nv p {
  font-size: 13px;
  color: #dc3545;
  margin: 0;
}

.ant-dropdown-menu-vertical.notification-downmenu0 {
  background-color: #fff;
  min-width: 280px;
  box-shadow: 0 0 4px rgb(0 0 0 / 6%);
  color: #475f7b;
  border-radius: 5px;
  font-size: 13px;
  border: 1px solid #efefef;
  width: 280px;
}

.ant-dropdown-menu-vertical.dropdown-downmenu0 {
  background-color: #fff;
  min-width: 150px;
  box-shadow: 0 0 4px rgb(0 0 0 / 6%);
  color: #475f7b;
  border-radius: 5px;
  font-size: 13px;
  border: 1px solid #efefef;
  width: 150px;
}

.menu-sm-scrol {
  width: 100%;
  float: left;
}

/* li.ant-dropdown-menu-item.ant-dropdown-menu-item-only-child {
    width: 280px;
    white-space: inherit;
    padding: 5px 21px;
} */

span.ant-dropdown-menu-title-content {
  width: 100%;
  float: left;
}

.menu-sm-scrol ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-sm-scrol p {
  border-bottom: 1px solid #d5dfea;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.menu-sm-scrol p a {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: initial;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #475f7b;
  font-size: 13px;
}

/* 
.menu-sm-scrol li {
    display: block;
    white-space: nowrap;
    border-bottom: 1px solid #d5dfea;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 10px;
}

.notifications-menu-nv a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
} */

.menu-sm-scrol li {
  padding: 10px 2px;
  text-decoration: none;
  border-bottom: 1px solid #d5dfea;
}

.menu-sm-scrol li a {
  color: #475f7b;
  font-size: 14px;
}

/*============end  navbar============*/

/*============start profile============*/

h3.page-title {
  display: block;
  padding: 0px;
  color: #475f7b;
  font-size: 1.75rem;
  margin: 0;
}

.left_frm {
  width: 100%;
  float: left;
  background: #fff;
  border-radius: 13px;
  padding: 18px 18px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  border: 1px solid rgba(72, 94, 144, 0.16);
}

.left_frm .ant-col.ant-form-item-label {
  width: 30%;
  color: #7792b1;
  font-size: 15px;
  line-height: 24px;
  font-weight: 400;
  text-align: left;
  float: left;
}

.left_frm .ant-col.ant-form-item-label label {
  width: 100%;
  color: #7792b1;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}

.left_frm .ant-col.ant-form-item-control {
  width: 70%;
  float: left;
}

.left_frm input.ant-input {
  background-image: linear-gradient(45deg, #40a2ed, #25b5b5),
    linear-gradient(#98acc3, #98acc3);
  color: #737373;
  min-height: 38px;
  display: initial;
  -webkit-background-clip: padding-box;
  background-color: transparent;
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  -webkit-transition: background 0s ease-out 0s;
  -o-transition: background 0s ease-out 0s;
  transition: background 0s ease-out 0s;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  float: none;
}

.left_frm span.ant-input-affix-wrapper.ant-input-password {
  padding: 0;
  background-image: linear-gradient(45deg, #40a2ed, #25b5b5),
    linear-gradient(#98acc3, #98acc3);
  color: #737373;
  min-height: 38px;
  display: flex;
  -webkit-background-clip: padding-box;
  background-color: transparent;
  background-position: center bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-size: 0 2px, 100% 1px;
  padding: 0;
  transition: background 0s ease-out 0s;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  float: none;
}
.left_frm .ant-tabs-tab-btn {
  font-weight: 500;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-badge {
  color: #1890ff;
}
.form-element-br {
  border: 1px solid #ededed;
  border-radius: 10px;
  padding: 31px 38px;
  width: 100%;
  float: left;
  margin-bottom: 3%;
  margin-top: 1px;
}

span.uploadimg button.ant-btn {
  height: auto;
  border: inherit;
  box-shadow: inherit;
}

.submitbtn button.ant-btn.ant-btn-primary {
  color: #fff;
  border-color: #40a2ed;
  background: #40a2ed;
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  height: auto;
  font-size: 16px;
  font-weight: 500;
  padding: 6px 19px;
  border-radius: 5px;
}

.setting_pl {
  width: 100%;
  float: left;
}

.setting_pl h3 {
  font-size: 26px;
}

.toggilebtn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  float: left;
  margin-top: 1%;
  margin-bottom: 1%;
}

.toggilebtn p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin-right: 11px;
}

.avatar-bordered_rt {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 25%;
  background: #ccc;
}

.avatar-bordered_rt img {
  width: 100%;
  float: left;
}

.avtart_top1 {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  align-items: center;
  background: #0000006b;
  color: #fff;
  padding: 10px 10px;
}

.avtart_top1 h4 {
  margin-bottom: 1px;
  color: #fff;
}

.avtart_top1 a {
  color: #fff;
}
.avatar-bordered_rt span.ant-avatar.ant-avatar-circle {
  width: 100% !important;
  height: 300px !important;
  display: flex;
  border-radius: inherit;
  justify-content: center;
  align-items: center;
  font-size: 96px !important;
}
.box-profile_rt {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid rgba(72, 94, 144, 0.16);
  padding: 13px 17px;
  margin-top: 22px;
  float: left;
}

.box-profile_rt p {
  color: #5a5a5a;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}

.box-profile_rt p span {
  color: #7792b1;
  font-weight: 400;
}

/*============end  Profile============*/

/*============start dashborad============*/

.nld-date {
  background: #f0f0f0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 7px;
  width: 100%;
  float: left;
  border-radius: 4px;
}

.nld-date h3 {
  margin: 0;
  font-size: 19px;
  line-height: 28px;
}

.nld-date p {
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
}

.nld-heading {
  width: 100%;
  float: left;
}

.post-news {
  width: 100%;
  float: left;
}
.post-news img {
  width: 100%;
  height: 223px;
}

.nld-heading h2 {
  font-size: 16px;
  margin: 0;
}

p.date-link {
  width: 100%;
  float: left;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.date-link i {
  margin-right: 3px;
}

.post-news p {
  font-size: 13px;
  color: #475f7b;
  margin: 0;
}

.news-letter {
  width: 100%;
  float: left;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 3%;
  padding-bottom: 2%;
}

.ekit-heading--title h2 {
  color: #475f7b;
  margin-bottom: 18px;
  font-weight: 400;
}

p.date-time-link {
  font-size: 13px;
  text-align: end;
  width: 100%;
  float: left;
  margin: 0;
}

.date-time-link i {
  margin: 0px 2px;
  margin-left: 9px;
}

/*============end  dashboard============*/

.video-para {
  background: #f4f4f4;
  padding: 11px 16px;
  width: 100%;
  float: left;
  margin-bottom: 10px;
  height: 146px;
  overflow: hidden;
  position: relative;
}
.searchbar-sb.targetsearch-fix0 {
  position: absolute;
  top: 8px;
  width: 26%;
  right: 21px;
  float: left;
}
.video-letter iframe {
  width: 100%;
  height: 100%;
}

.video-para h3 {
  font-size: 20px;
  margin: 0;
  color: #333;
  line-height: 21px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.video-para b {
  font-size: 13px;
  color: #525151;
}

.video-para p {
  font-size: 12px;
  color: #6a6a6a;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 17px;
}
.video-letter {
  width: 100%;
  float: left;
  margin-top: 10px;
  margin-bottom: 10px;
}
.tabs_dashboard-training {
  width: 100%;
  float: left;
  position: relative;
  width: 100%;
  float: left;
  background: #fff;
  border-radius: 13px;
  padding: 18px 18px;
  transition: 0.5s;
  border: 1px solid rgba(72, 94, 144, 0.16);
}

/* span.time-vo {
  color: #646464;
  font-size: 13px;
} */
span.time-vo {
  color: #151515;
  font-size: 13px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-weight: 500;
  position: absolute;
  bottom: 10px;
}
span.time-vo i {
  margin-left: 6px;
  margin-right: 2px;
  font-size: 13px;
}

.video-img img {
  width: 100%;
  height: 100%;
  float: left;
}

.video-img {
  height: auto;
  overflow: hidden;
  position: relative;
  width: 100%;
  float: left;
}
.playbtn-0n {
  position: absolute;
  width: 100%;
  float: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.playbtn-0n span.anticon.anticon-caret-right {
  font-size: 3em;
  line-height: 1.5em;
  height: 1.5em;
  width: 3em;
  padding: 0;
  cursor: pointer;
  opacity: 1;
  border: 0.06666em solid #fff;
  background-color: rgba(43, 51, 63, 0.7);
  border-radius: 0.3em;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 27px;
}
.video-img video.video-react-video {
  width: 100%;
  float: left;
}
.upcoming-letter {
  background: #40a2ed;
  width: 100%;
  border-radius: 8px;
  float: left;
  padding: 3px;
}
.video-img .video-react .video-react-big-play-button {
  left: inherit;
  top: 42%;
}
.video-img .video-react {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inprogress-ip {
  background: #ffc107;
}

.complete-ct {
  background: #059d6f;
}

.taskboard-middle {
  background: #fff;
  width: 100%;
  float: left;
  border-radius: 6px;
  padding: 12px 13px;
}
.video-img span.anticon.anticon-file-pdf {
  background: #333;
  height: 162px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
}
.upcoming-heading {
  width: 100%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 15px;
  color: #fff;
}

.upcoming-heading h3 {
  color: #fff;
  margin: 0;
  font-weight: 400;
  font-size: 21px;
}

.chat-left-cl {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  background-color: #f7f9fb;
  border-radius: 5px;
  padding: 0px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  border: 1px solid rgba(72, 94, 144, 0.16);
  height: 700px;
  overflow: hidden;
}

img.avatar-user {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100% !important;
  text-transform: uppercase;
}

.box-header-cl {
  color: #475f7b;
  padding: 0.5rem 1.5rem;
  position: relative;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  float: left;
}

.box-header-cl p {
  margin: 0;
}

.box-body-cl {
  padding: 15px 13px;
  background: #f7f9fb;
  border-radius: 5px;
  width: 100%;
  float: left;
}

.chat-heading-cl h3 {
  color: #7792b1;
  font-size: 27px;
  margin: 0;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 5px;
}

.customtab-cl .ant-tabs-tab-btn {
  outline: none;
  transition: all 0.3s;
  font-size: 13px;
  font-weight: 500;
}

input.ant-input {
  color: #475f7b;
  border: 1px solid #d5dfea;
  border-radius: 20px;
}

button.ant-btn.ant-btn-icon-only.ant-input-search-button {
  border-radius: 0 18px 18px 0 !important;
  border-left: 0px;
}

.box-shadowed-cl {
  display: flex;
  width: 100%;
  float: left;
  padding: 11px 10px;
  -webkit-transition: background-color 0.2s linear;
  transition: background-color 0.2s linear;
  border-radius: 5px;
  background: #fff;
  margin-bottom: 5px;
  margin-top: 5px;
  border: 1px solid #f0f2f5;
}
.box-shadowed-cl:hover {
  background: #40a2ed !important;

  border: 1px solid #6eae9d;
  color: #fff !important;
}
.box-shadowed-cl:hover span {
  color: #fff !important;
}
.box-shadowed-cl:hover p {
  color: #fff !important;
}
.avatar-lg-cl img {
  width: 44px;
  height: 44px;
  line-height: 48px;
  font-size: 1.286rem;
  border-radius: 100px;
}

.searchbar-sb {
  width: 100%;
  float: left;
  margin-bottom: 15px;
  margin-top: 11px;
}

.media-body-cl p {
  color: #737373;
  font-size: 13px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  line-height: 13px;
}

.chat-name-cl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  float: left;
}

.media-body-cl {
  width: 75%;
  float: left;
}

.avatar-lg-cl {
  width: 25%;
  float: left;
}

span.time-cl {
  color: #7792b1;
  font-size: 11px;
}

.hover-primary-cl {
  color: #475f7b;
  font-size: 13px;
}

.customtab-cl {
  width: 100%;
  float: left;
}

.media-list-cl {
  width: 100%;
  float: left;
  height: 460px;
  overflow-y: scroll;
}

/* width */

.media-list-cl::-webkit-scrollbar {
  width: 3px;
}

/* Track */

.media-list-cl::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

.media-list-cl::-webkit-scrollbar-thumb {
  background: rgb(174, 213, 245);
}

/* Handle on hover */

.media-list-cl::-webkit-scrollbar-thumb:hover {
  background: rgb(174, 213, 245);
}

.media-cg {
  width: 100%;
  float: left;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 12px;
  padding-top: 12px;
  padding-left: 3px;
  padding-right: 3px;
}

.avatar-lg-cg img {
  width: 44px;
  height: 44px;
  line-height: 48px;
  font-size: 1.286rem;
  border-radius: 100%;
}

.avatar-lg-cg {
  width: 25%;
  float: left;
}

.media-body-cg {
  width: 75%;
  float: left;
}
.media-body-cg strong {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #1b639a;
  /* font-weight: 500; */
}
.media-body-cg strong svg {
  margin-left: 4px;
  /* color: #fff; */
}
.box-shadowed-cl .media-body-cg p {
  color: #475f7b;
  font-size: 14px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.active-chat strong svg,
.active-chat .media-body-cg p {
  color: #fff;
}

.media-cg:hover {
  background-color: #40a2ed;
  color: #ffffff;
  border-radius: 5px;
}

.media-cg:hover p,
.media-cg:hover span,
.media-cg:hover strong .media-body-cg:hover strong {
  color: #fff;
}
.box-shadowed-cl:hover .media-body-cg strong,
.media-body-cg strong:hover {
  color: #fff;
}
.publicchat-pc {
  background: inherit;
  border: inherit;
}

/* .online-show::after {
  content: "";
  position: absolute;
  right: 10px;
  bottom: -1px;
  display: inline-block;
  width: 13px;
  height: 13px;
  border-radius: 100%;
  border: 2px solid #f7f9fb;
  background: #40a2ed;
} */

.online-show {
  position: relative;
  width: 54px;
  float: left;
}

.box-header-cm {
  color: #475f7b;
  display: block;
  padding: 1.5rem 1.5rem;
  position: relative;
  border-bottom: 1px solid rgba(72, 94, 144, 0.16);
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  width: 100%;
  float: left;
}

.box-middle-cm {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0px;
  transition: 0.5s;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(72, 94, 144, 0.16);
  float: left;

  height: 700px;
}

.avatar-lg-cm {
  width: 11%;
  float: left;
}

.media-body-cm {
  width: 88%;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.view-detail-vd {
  width: 89%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.InfoCircle-icon {
  width: 7%;
  float: left;
}
.InfoCircle-icon button.ant-btn {
  padding: 0;
  border: initial;
  font-size: 20px;
  color: #5e5e5e;
}
.part-details-p0 {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  float: left;
  margin-bottom: 9px;
  margin-top: 7px;
}
button.ant-btn.remove-btn-rb {
  border: none;
  font-weight: 500;
  color: #40a2ed;
  font-size: 12px;
}
span.partticipants-p {
  font-weight: 600;
  color: #272727;
  font-size: 14px;
}
.hover-primary-cm strong {
  color: #475f7b;
  font-size: 15px;
  line-height: 12px;
}

.hover-primary-cm {
  width: 100%;
  float: left;
  line-height: 20px;
}

.avatar-lg-cm img {
  width: 44px;
  height: 44px;
  border-radius: 100px;
}

._2aBzC {
  width: 100%;
  float: left;
}

.left-msg-cm {
  position: relative;
  /* background-color: #e6ecf3;
  border: 1px solid rgba(0, 0, 0, 0.125); */
  border-radius: 0.25rem;
  width: 100%;
  float: left;
  padding: 3px 15px;
  margin-bottom: 8px;
  margin-top: 8px;
}

.box-body-cm {
  width: 100%;
  float: left;
}

._2GAT7 {
  width: 10%;
  float: left;
}

._2GAT7 img {
  width: 44px;
  height: 44px;
  border-radius: 7%;
}

.msg-bubble {
  float: left;
  width: 90%;
}

.msg-info {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  align-items: center;
  float: left;
}

.msg-text {
  color: #1b1c1d;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  width: 100%;
  float: left;
  overflow-wrap: break-word;
  white-space: pre-wrap;
}
.msg-text-imgmain {
  width: 100%;
  float: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}
.msg-text-img {
  width: 20%;
  float: left;
}
.img-playicon {
  position: relative;
  width: 100%;
  height: 100%;
}
.img-playicon  svg {
  position: absolute;
  z-index: 99;
  right: 40%;
  top: 40%;
  font-size: 27px;
  color: #fff;
}
.msg-text-img img {
  width: 100px;
  height: 100px;
  /* margin-top: 9px;
  margin-bottom: 9px; */
}
.msg-info-time {
  color: #6c757d;
  font-size: 12px;
}

.msg-info-name {
  color: #475f7b;
  font-size: 15px;
  font-weight: 700;
  margin-right: 10px;
}
.right-msg-cm .left-msg-cm {
  background: #40a2ed;
  color: #fff;
}

._2aBzC.right-msg-cm {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.right-msg-cm .msg-info-name,
.right-msg-cm .msg-info-time,
.right-msg-cm .msg-text {
  color: #fff;
}

.scrollbar0 {
  width: 100%;
  float: left;
  height: 500px;
  overflow-y: scroll;
  /* flex-direction: 'column-reverse'!important; */
  padding: 15px 21px;
  position: relative;
}
.mkey {
  width: 100%;
  float: left;
}
.chatfull-bm {
  width: 100%;
  float: left;
  height: 505px;
}
.checksc {
  width: 95%;
  height: 100%;
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
}
/* width */

.scrollbar0::-webkit-scrollbar {
  width: 3px;
}

/* Track */

.scrollbar0::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

.scrollbar0::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

.scrollbar0::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.box-footer-cm {
  width: 100%;
  float: left;
  position: relative;
  border: 1px solid #d9d9d9;
  border-bottom: 0;
}
.box-footer-cm span.ant-input-affix-wrapper {
  border: 0 !important;
  border-top: 0 !important;
}
.all-icon-input {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  float: left;
}
.upload-plus-up {
  background: #262626eb;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  color: #fff;
  margin-right: 12px;
}
.upload-plus-up a {
  width: 100%;
  float: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-plus-up svg {
  font-size: 18px;
}
section.emoji-mart.emoji-mart-light {
  position: absolute;
  bottom: 83px;
  right: 0;
}
.box-footer-cm .ant-input-affix-wrapper > input.ant-input {
  background-color: inherit;
  border: initial;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  height: auto;
  padding: 22px 19px;
  height: 75px;
}

.box-footer-cm span.anticon.anticon-send {
  background-color: #40a2ed;
  border-color: #40a2ed;
  color: #ffffff;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
}

.box-footer-cm span.anticon.anticon-send svg {
  font-size: 20px;
  color: #fff;
}

.box-footer-cm input.ant-input {
  color: #8e9ba8;
  font-size: 15px;
}

.box-footer-cm input.ant-input::placeholder {
  color: #d5dfea;
  font-size: 15px;
}

.box-header-cr h4 {
  color: #475f7b;
  font-size: 15px;
  margin: 0;
}

.box-header-cr {
  padding: 20px 20px;
  width: 100%;
  float: left;
}

.box-shadowed-cr.box-shadowed-cl {
  border: initial;
}

.media-list-cr {
  width: 100%;
  float: left;
  height: 614px;
  overflow-y: scroll;
}

/* width */

.media-list-cr::-webkit-scrollbar {
  width: 4px;
}

/* Track */

.media-list-cr::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */

.media-list-cr::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */

.media-list-cr::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.todo-list-uc {
  width: 100%;
  float: left;
  margin-bottom: 10px;
  margin-top: 10px;
}

.todo-list-uc span.ant-tag {
  font-size: 10px;
  margin-left: 10px;
  padding: 1px 7px;
  line-height: 18px;
}

.todo-list-uc label.ant-checkbox-wrapper {
  color: #475f7b;
  font-size: 13px;
}

.calendar-data-cd .events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.calendar-data-cd .events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.calendar-data-cd .notes-month {
  font-size: 28px;
  text-align: center;
}

.calendar-data-cd .notes-month section {
  font-size: 28px;
}

.calendar-data-cd {
  padding: 13px 20px;
  background: #fff;
  border: 1px solid #e2e5ed;
  border-radius: 6px;
  width: 100%;
  float: left;
}

.calendar-data-cd
  .ant-picker-calendar-full
  .ant-picker-panel
  .ant-picker-body
  th {
  height: auto;
  padding: 12px 12px 12px 0;
  line-height: 18px;
  background: #d5dfea;
  color: #777777;
}
.groupb-btn-gb button.ant-btn {
  background: #40a2ed;
  color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-gchatbx0 {
  width: 100% !important;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 25px !important;
}
.modal-gchatbx0 .ant-modal-content {
  width: 40%;
}
.input-group-ig {
  width: 100%;
  float: left;
}
/* .input-group-ig .ant-row.ant-form-item {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
} */
.modal-gchatbx0 .ant-modal-body {
  width: 100%;
  float: left;
  padding: 10px 51px;
  max-height: 600px;
  overflow-y: scroll;
}
/* width */
.modal-gchatbx0 .ant-modal-body::-webkit-scrollbar {
  width: 3px;
}

/* Track */
.modal-gchatbx0 .ant-modal-body::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.modal-gchatbx0 .ant-modal-body::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.modal-gchatbx0 .ant-modal-body::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.input-group-ig .ant-col.ant-form-item-label {
  text-align: left;
  /* width: 100%;
  float: left; */
}
.input-group-ig input.ant-input {
  border-radius: 4px;
  height: 41px;
  width: 100%;
  float: left;
}
.input-group-ig input.ant-input {
  height: 41px;
  width: 100%;
  float: left;
  cursor: pointer;
  background: #fff;
  border: 2px solid #ededed;
  border-radius: 3px;
  border-top: #ededed 1px solid !important;
  border-left: #ededed 1px solid !important;
  border-bottom: #ededed 3px solid !important;
  border-right: #ededed 3px solid !important;
  color: #748194;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  -webkit-transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
  -o-transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
}

.input-group-ig textarea.ant-input {
  height: 41px;
  width: 100%;
  float: left;
  cursor: pointer;
  background: #fff;
  border: 2px solid #ededed;
  border-radius: 3px;
  border-top: #ededed 1px solid !important;
  border-left: #ededed 1px solid !important;
  border-bottom: #ededed 3px solid !important;
  border-right: #ededed 3px solid !important;
  color: #748194;
  box-shadow: inset 0 1px 2px rgb(0 0 0 / 10%);
  -webkit-transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
  -o-transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
  transition: color 0.3s, border 0.3s, background 0.3s, opacity 0.3s;
}
.particpants-searchbox {
  width: 100%;
  float: left;
}
.particpants-searchbox .searchbar-sb {
  width: 100%;
  float: left;
}
.particpants-searchbox .input.ant-input {
  border: 2px solid #ededed;
  border-radius: 3px;
  border-top: #ededed 1px solid !important;
  border-left: #ededed 1px solid !important;
  border-bottom: #ededed 3px solid !important;
  border-right: #ededed 3px solid !important;
  color: #748194;
  height: 37px;
}
.particpants-searchbox
  button.ant-btn.ant-btn-icon-only.ant-input-search-button {
  border-radius: 0px 5px 5px 0px !important;
  height: 43px;
  border: 2px solid #eeeeee;
  border-left: 0px;
}
.particpants-searchbox .searchbar-sb input.ant-input {
  border-radius: 5px 0px 0px 5px;
  height: 43px;
  border: 2px solid #eeeeee;
}
.input-group-ig .ant-form-item-label > label {
  color: #748194;
  font-size: 16px;
}

/* h2.addparticipants-heading::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 35px;
  background: #40a2ed;
  left: 0;
} */
h2.addparticipants-heading {
  font-size: 20px;
  margin: 0;
  position: relative;
  padding-left: 3px;
  line-height: 33px;
  color: #5f6368;
}
.emoji-left {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.emoji-left svg.emoji-text-et {
  margin-right: 11px;
  font-size: 22px;
}
.main-grop_mg {
  width: 100%;
  float: left;
  max-height: 253px;
  overflow-y: scroll;
  height: auto;
}
/* width */
.main-grop_mg::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.main-grop_mg::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.main-grop_mg::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.main-grop_mg::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.meadi-grp-mg {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f4f4f4;
  border-radius: 12px;
  margin-bottom: 11px;
  border-bottom: 3px solid #40a2ed;
}
.meadi-grp-mg:hover {
  background-color: #40a2ed;
}
.meadi-grp-mg .box-shadowed-cl {
  display: flex;
  width: 90%;
  justify-content: flex-start;
  align-items: center;
  float: left;
  background: inherit;
}
.meadi-grp-mg .box-shadowed-cl:hover,
.hover-primary-cl:hover {
  background: inherit !important;
  color: #fff;
}
.meadi-grp-mg .box-shadowed-cl:hover .hover-primary-cl {
  color: #fff;
}
.checkbox-chat-mg {
  width: 10%;
  float: left;
}
.meadi-grp-mg .box-shadowed-cl .avatar-lg-cl {
  width: 15%;
  float: left;
}
.meadi-grp-mg .box-shadowed-cl .media-body-cl {
  width: 85%;
  float: left;
}
.btn-grp-mg {
  width: 100%;
  float: left;
  text-align: end;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  padding-bottom: 10px;
  padding-top: 10px;
}
.btn-grp-mg button.ant-btn.create-mg {
  background: #40a2ed;
  color: #fff;
  border-radius: 2px;
  padding: 5px 26px;
  height: auto;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
}
.btn-grp-mg button.ant-btn.cancel-mg {
  background: #ffffff;
  color: #626262;
  border-radius: 2px;
  padding: 5px 26px;
  height: auto;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
}
.ant-tooltip-inner {
  min-width: 30px;
  min-height: 32px;
  padding: 6px 8px;
  color: #fff;
  text-align: left;
  text-decoration: none;
  word-wrap: break-word;
  background-color: #40a2ed !important;
  border-radius: 2px;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
    0 9px 28px 8px rgb(0 0 0 / 5%);
}
.ant-tooltip-arrow-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 5px;
  height: 5px;
  margin: auto;
  background-color: #40a2ed !important;
  content: "";
  pointer-events: auto;
}
.modal-gchatbx0 .ant-modal-header {
  padding: 16px 24px;
  color: rgb(255 255 255 / 85%);
  background: #40a2ed;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}
.modal-gchatbx0 .ant-modal-title {
  margin: 0;
  color: rgb(255 255 255);
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  word-wrap: break-word;
}
.modal-gchatbx0 .ant-modal-close-x svg {
  color: #fff;
}
.group-img-avtar {
  width: 100%;
}
.group-img-avtar img {
  width: 100px;
  height: 100px;
  border-radius: 11px;
  margin-bottom: 2%;
  margin-top: 2%;
}
.start-conversation-sc {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: 2%;
  margin-bottom: 2%;
  flex-direction: column;
}
.input-private-chat-ipc {
  width: 100%;
  float: left;
  display: flex;
  /* justify-content: center;
  align-items: center; */
}
.input-private-chat-ipc .ant-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  float: left;
  flex-direction: row !important;
}
.input-private-chat-ipc .ant-form-item-control-input-content {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
.input-private-chat-ipc .ant-col.ant-form-item-control {
  width: 50%;
  float: left;
  display: flex;
}
.input-private-chat-ipc .ant-col.ant-form-item-label {
  width: 50%;
  float: left;
  display: flex;
  justify-content: flex-start;
}
.input-private-chat-ipc .ant-col.ant-form-item-label label {
  font-weight: 600;
}

.start-conversation-sc p {
  color: #8a8a8a;
  font-size: 19px;
  font-weight: 500;
  line-height: 22px;
  margin: 0;
  text-align: center;
}
.start-conversation-sc svg {
  font-size: 38px;
  margin-bottom: 11px;
}
.ant-switch-checked {
  background-color: #40a2ed;
}
.login-userdrpdown a {
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  background: #40a2ed;
  display: inline-block;
  line-height: 36px;
  padding: 4px 17px;
  border-radius: 25px;
}
.login-userdrpdown a:hover {
  color: #fff;
}
.login-userdrpdown {
  display: flex;
  justify-content: flex-end;
}
.headerlogin-hl {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
  padding-top: 10px;
}
.headerlogin-hl svg {
  font-size: 32px;
  color: #fff;
}
.headerlogin-hl h1 {
  font-size: 19px;
  line-height: 16px;
  color: #fff;
  margin: 0;
}
.ant-tooltip.ant-tooltip-placement-leftTop .ant-tooltip-inner {
  font-weight: 500 !important;
  line-height: 16px !important;
  font-size: 12px;
}
.active-chat {
  background: #40a2ed !important;
  border: 1px solid #6eae9d;
  color: #fff !important;
}
.active-chat p,
.active-chat span {
  color: #fff;
}

.dropupload-menu0 {
  background: #f8f8f8;
  width: 220px;
  box-shadow: 0 0 0 1px rgba(var(--sk_foreground_low, 29, 28, 29), 0.13),
    0 4px 12px 0 rgba(0, 0, 0, 0.12);
}

.dropupload-menu0 span.ant-dropdown-menu-title-content i {
  font-size: 13px;
  margin-right: 7px;
}
.dropupload-menu0 span.ant-dropdown-menu-title-content {
  width: 100%;
  float: left;
  color: #616061;
  font-weight: 500;
  font-size: 13px;
  padding: 6px 4px;
}
.dropupload-menu0
  li.ant-dropdown-menu-item.shortcut-s0
  span.ant-dropdown-menu-title-content {
  color: #a6a6a6;
  font-size: 11px !important;
  font-weight: 500;
}
.dropupload-menu0 li.ant-dropdown-menu-item {
  /* padding: 5px 12px; */
}
ul.ant-dropdown-menu.ant-dropdown-menu-root.ant-dropdown-menu-vertical.ant-dropdown-menu-light.dropupload-menu0 {
  padding: 0;
}
.upload-plus-up svg {
  color: #fff;
}
.foteronetimg-fci {
  position: relative;
  width: 100%;
  float: left;
  display: flex;
}
/* .box-footer-cm span.ant-input-prefix {
  width: 20%;
} */
.imgchat-imf .ant-image {
  margin-right: 7px;
}
span.ant-input-prefix .prifix-input-b0 {
  width: 100%;
  float: left;
}
.imgchat-imf {
  width: 13%;
  position: relative;
  float: left;
  padding: 0;
  margin: 0;
  text-align: center;
}
.imgchat-imf .ant-image-mask {
  border-radius: 10px;
}
button.ant-btn.imgcross-imf {
  position: absolute;
  left: 6px;
  border: 1px solid #767676;
  padding: 0;
  color: #ffffff;
  border-radius: 31px;
  width: 18px;
  height: 18px;
  background: #000;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
}
.box-middle-cm .ant-input-affix-wrapper-focused,
.box-middle-cm .ant-input-affix-wrapper:focus {
  border-color: #1890ff;
  box-shadow: inherit;
  outline: inherit;
}
button.ant-btn.imgcross-imf svg {
  font-size: 10px;
}
button.ant-btn.imgcross-imf span.anticon.anticon-close {
  display: flex;
  justify-content: center;
  align-items: center;
}
.imgchat-imf img.ant-image-img {
  width: 100%;
  height: auto;
  padding: 0;
  margin: 0;
  border-radius: 10px;
}
.chatfull-bm {
  /* width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  min-height: 458px; */
}
.ant-popover-inner {
  width: 44%;
  float: left;
  border: 1px solid #e6fff4;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-popover-content {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 601px;
}
.ant-popover-inner-content {
  width: 100%;
  float: left;
  overflow-y: scroll;
  max-height: 232px;
  /* height: 232px; */
}
/* width */
.ant-popover-inner-content::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.ant-popover-inner-content::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.ant-popover-inner-content::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
.ant-popover-inner-content::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.popover-tool-pl {
  width: 100%;
  float: left;
}
.date-time-dt {
  width: 100%;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e6fff4;
  margin-bottom: 7px;
  padding-bottom: 5px;
  flex-direction: column;
}
h4.Name__dt {
  font-size: 12px;
  width: 100%;
  margin: 0;
  float: left;
  font-weight: 700;
}
p.fullname__dt {
  font-size: 11px;
  width: 100%;
  margin: 0;
  float: left;
  line-height: 12px;
  color: #666;
  font-weight: 500;
}
.description-d0 {
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical; */
}
/* .viewmorem0 button.ant-btn {
  font-size: 9px;
  padding: 1px 3px;
  height: auto;
  margin-top: 4px;
  background: #50b1ff;
  color: #fff;
  font-weight: 600;
} */
.unread-cbms {
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-boxvideo0 .video-react .video-react-big-play-button {
  top: 46%;
  left: 46%;
}
.video-img video.video-react-video {
  width: 100%;
  float: left;
  height: 200px;
}
.video-img .video-react {
  width: 100%;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  padding: 0 !important;
}
.values-beliefs-page h2 {
  font-size: 19px;
}

.video-img .video-react .video-react-big-play-button {
  left: inherit;
  top: 42%;
  display: none;
}
.searchbar-sb .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  font-size: 11px!important;

}
.searchbar-sb  .ant-input-group > .ant-input-affix-wrapper:not(:last-child) .ant-input {

  font-size: 15px!important;
}
.searchbar-sb  .ant-input-affix-wrapper > input.ant-input {
  background-color: #fff;}

  .filechoose-fc input[type="file"] {
    z-index: 100;
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    opacity: 0;
}
.filechoose-fc {
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
}
@media only screen and (max-width: 959px) {
  .imgchat-imf {
    width: 30%;
    position: relative;
    float: left;
    padding: 0;
    margin: 0;
    text-align: center;
  }
  .msg-text {
    color: #1b1c1d;
    font-size: 12px;
  }
  span.time-vo {
    position: inherit;
    width: 100%;
  }
  .video-para {
    height: auto;
    overflow: hidden;
  }
  .msg-text-img {
    width: 28%;
    float: left;
  }
  .msg-text-img img {
    width: 60px;
    height: 60px;
    margin-top: 9px;
    margin-bottom: 9px;
  }
  .ant-btn-primary {
    color: #fff;
    border-color: #40a2ed;
    background: #40a2ed;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
  }
  ._2GAT7 img {
    width: 39px;
    height: 39px;
    border-radius: 100%;
  }
  .left-msg-cm {
    padding: 10px 3px;
  }
  ._2GAT7 {
    width: 19%;
    float: left;
  }
  .msg-bubble {
    width: 78%;
    float: left;
  }
  .ant-dropdown-menu-vertical.dropdown-downmenu0 {
    background-color: #fff;
    min-width: 142px;
    box-shadow: 0 0 4px rgb(0 0 0 / 6%);
    color: #475f7b;
    border-radius: 5px;
    font-size: 13px;
    border: 1px solid #efefef;
    width: 102px;
  }
  .avatar-lg-cm {
    width: 20%;
    float: left;
  }
  .media-body-cm {
    width: 79%;
    float: left;
  }
  .mobile-sidebar-0 button.ant-drawer-close {
    background: #40a2ed;
    color: #fff;
    padding: 10px 10px;
    border-radius: 7px;
    font-weight: bolder;
  }
  .mobile-sidebar-0 .ant-drawer-header-title {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    float: left;
  }
  .mobile-sidebar-0 .ant-menu-submenu-title {
    font-weight: 500;
    color: #333;
    font-size: 12px;
  }
  .upcoming-heading h3 {
    color: #fff;
    margin: 0;
    font-weight: 400;
    font-size: 17px;
  }
  h3.page-title {
    display: block;
    padding: 0px;
    color: #475f7b;
    font-size: 21px;
    margin: 0;
  }
  .mobile-sidebar-0 span.ant-menu-title-content,
  .mobile-sidebar-0 .ant-menu-item a {
    font-weight: 500;
    color: #333;
    font-size: 12px;
  }
  .content-top-agile h2 {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    margin-bottom: 1px;
  }
  .login-form-button button.ant-btn.ant-btn-primary {
    font-size: 13px;
    font-weight: 600;
    padding: 8px 24px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 0px;
  }
  .frm-Login .ant-input-affix-wrapper > input.ant-input::placeholder {
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
  }
  .frm-Login
    .ant-form-item-has-error
    :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
  .ant-input-affix-wrapper > input.ant-input {
    background-color: #f7f6f6;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
  }
  a.login-form-forgot {
    font-weight: 500;
    text-align: end;
    width: 100%;
    float: left;
    font-size: 12px;
  }
  .basic_checkbox_0 label.ant-checkbox-wrapper {
    font-size: 11px;
    color: #646464;
    font-weight: 600;
  }
  .content_all {
    margin: 15px 1px;
    min-height: 600px;
    padding: 25px 12px;
    height: 100%;
    margin-left: 0px;
  }
  .left_frm .ant-col.ant-form-item-label label {
    width: 100%;
    color: #7792b1;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }
  .box-profile_rt p {
    color: #5a5a5a;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
  }
  .notifications-menu-nv {
    width: 30%;
    float: left;
  }
  .avatar-bordered_rt {
    width: 100%;
    float: left;
    position: relative;
    margin-top: 12%;
  }
  .nav_dropuser {
    width: 70%;
    float: left;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
  }
  .logouser {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 18px;
  }
  .values-beliefs-page h2 {
    font-size: 15px;
    font-weight: 700;
  }
  .values-beliefs-page p {
    font-size: 12px;
  }
  .nld-date {
    background: #f0f0f0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px 25px;
    width: auto;
    float: left;
    border-radius: 4px;
  }
  header.ant-layout-header.navbar_top {
    padding: 0px 4px !important;
  }
  .nld-heading h2 {
    font-size: 14px;
    margin: 0;
    line-height: 22px;
  }
  .post-news p {
    font-size: 12px;
    color: #475f7b;
    margin: 0;
  }
  .name-fl-l p {
    margin: 0;
    margin-right: 4px;
    line-height: 9px;
    font-weight: 500;
    font-size: 9px;
  }
  .notifications-menu-nv i {
    font-size: 19px;
    color: #333;
    margin-right: 4px;
  }
  .ant-dropdown-menu-vertical.notification-downmenu0 {
    background-color: #fff;
    min-width: 194px;

    width: 215px;
  }
  .flexbox-nv h4 {
    font-size: 13px;
    color: #475f7b;
    margin: 0;
  }
  .menu-sm-scrol p a {
    font-size: 11px;
  }
  .form-element-br {
    border: 1px solid #ededed;
    border-radius: 10px;
    padding: 20px 16px;
    width: 100%;
    float: left;
    margin-bottom: 3%;
    margin-top: 1px;
  }
  .image_nv img {
    width: 32px;
    height: 32px;
    border-radius: 100px;
  }
}


@import "~antd/dist/antd.css";
.ant-spin.ant-spin-spinning.loader-ld {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.test1{
  height: 1.1rem;
  width: 1.1rem;
  margin-left: 1rem;
  }
  /* .test23{
    overflow:scroll;
  } */
.loader-ld .ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 44px;
  height: 44px;
  background-color: #525456;
  border-radius: 100%;
}

.ant-spin.ant-spin-spinning.loader-ld {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-chat .ant-spin-dot-item {
  position: absolute;
  display: block;
  width: 30px;
  height: 30px;
  background-color: #525456;
  border-radius: 100%;
}
.loader-chat {
}

.video-js.vjs-default-skin .vjs-big-play-button { display: none; }